import React from 'react';
import { Box, Typography, TypographyVariant as Variant } from '@mui/material';

const SwBulletText = (props: SwBulletTextProps) => {
    return (
        <Box display={'flex'}>
            <Box mr={1}>
                <Typography variant={props.variant}>● </Typography>
            </Box>
            <Typography variant={props.variant}>{props.children}</Typography>
        </Box>
    );
};

interface SwBulletTextProps {
    children: any;
    variant: Variant;
}

export default SwBulletText;
