import { Info } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { Box, Fade, styled } from '@mui/material';
import { grey5, grey6, white } from '../../../../lib/components/bedrock/SwColors';
import SwLine from '../../../../lib/components/bedrock/layout/SwLine';
import SwSpacer from '../../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../../lib/components/bedrock/typography/SwTypography';
import { isDefined } from '../../../../lib/helpers/object';
import SwSectionCard from '../../SwSectionCard';
import { SwTooltip } from '../../SwTooltip';

const StyledGrid = styled(Box)`
    display: flex;
    align-items: stretch;
    flex-direction: column;

    &.hover {
        cursor: pointer;
    }

    &.hover-right {
        cursor: pointer;
        background: white;
        border-radius: 8px;
    }

    &.active-right {
        background: ${white};
        border-radius: 8px;
    }
`;

const StyledRightBox = styled(Box)`
    display: flex;
    align-items: center;
    padding-right: 8px;
`;

const StyledHowTo = styled('div')`
    background-color: ${grey6};
    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: 8px;
`;

const SwAssessmentDetails = (props: SwAssessmentDetailsProps) => {
    const [displayAssessmentInfo, setDisplayAssessmentInfo] = useState(false);

    const toggleInfoAssessment = (value: boolean) => {
        if (props.tooltipAssessmentContent) {
            setDisplayAssessmentInfo(value);
        }
    };

    return (
        <StyledGrid alignItems={'center'}>
            <SwSectionCard
                defaultOpen={false}
                emoji={
                    <Box display={'flex'} marginRight={'8px'}>
                        {props.icon}
                    </Box>
                }
                fullWidth={true}
                hoverChevronOnly={true}
                openable={true}
                title={
                    <Box alignItems={'center'} display={'flex'} height={'0px'}>
                        <Box flex={0.5}>
                            <SwTypography variant={'body2'}>{props.name}</SwTypography>
                        </Box>
                        <Box flex={1}>
                            <SwTypography bold={true} variant={'body2'}>
                                {props.tooltipAssessmentContent || 'N/A'}
                            </SwTypography>
                        </Box>
                    </Box>
                }
            >
                <StyledGrid onClick={() => toggleInfoAssessment(!displayAssessmentInfo)}>
                    <SwTooltip
                        arrow={true}
                        disableFocusListener={true}
                        disableHoverListener={true}
                        disableTouchListener={true}
                        open={displayAssessmentInfo}
                        title={props.tooltipAssessmentContent || 'N/A'}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                        onClose={() => toggleInfoAssessment(false)}
                    >
                        <>
                            {isDefined(props.children) ? (
                                <>
                                    <StyledRightBox>{props.children}</StyledRightBox>
                                    <SwLine color={grey5} direction={'horizontal'} spacingBottom={2} />
                                </>
                            ) : null}

                            {props.howTo ? (
                                <>
                                    <StyledHowTo>
                                        <Box alignItems={'center'} display={'flex'}>
                                            <Info size={20} />
                                            <SwSpacer direction={'horizontal'} spacing={1} />
                                            <SwTypography bold={true} variant={'h5'}>
                                                How to read this
                                            </SwTypography>
                                        </Box>
                                        <SwSpacer direction={'vertical'} spacing={1} />
                                        {props.howTo}
                                    </StyledHowTo>
                                </>
                            ) : null}
                        </>
                    </SwTooltip>
                </StyledGrid>
            </SwSectionCard>
        </StyledGrid>
    );
};

interface SwAssessmentDetailsProps {
    icon: any;
    name: string;
    compactDisplay?: boolean;
    children?: any;
    half?: boolean;
    howTo?: any;
    showInfo: boolean;
    tooltipCriteriaContent?: JSX.Element;
    tooltipAssessmentContent?: string;
}

export default SwAssessmentDetails;
