import React, { useEffect } from 'react';
import MDX from '@mdx-js/runtime';
import { styled } from '@mui/material';
import { boldFontFamily } from '../../../BaseLayout';
import GhostGrey from '../../../assets/icons/ghost-grey.svg';
import { black, grey3, grey5, grey7, white } from '../../../lib/components/bedrock/SwColors';
import { ImageAspectRatios } from '../../../lib/enums/ImageAspectRatios';
import { getImageUrl } from '../../../lib/helpers/image';
import { slug } from '../../../lib/helpers/string';
import SwLink from '../SwLink';
import { WithImagePicker, WithPickerProps } from '../common/WithPicker';

const StyledDiv = styled('div')<{ iseditorial?: string }>`
    margin-top: ${({ theme }) => theme.spacing(2)};
    font-size: 16px;
    line-height: 28px;
    color: ${grey3};

    blockquote {
        color: ${({ theme }) => theme.palette.primary.main};
        border-left: none;
        font-style: normal;
        font-family: ${boldFontFamily};
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
        border-top: 1px solid ${grey5};
        border-bottom: 1px solid ${grey5};
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: ${({ theme }) => theme.spacing(4)};
        padding-bottom: ${({ theme }) => theme.spacing(4)};
        margin-top: ${({ theme }) => theme.spacing(6)};
        margin-bottom: ${({ theme }) => theme.spacing(6)};
        text-align: center;
    }

    strong {
        font-family: ${boldFontFamily};
    }

    p,
    ul,
    ol {
        color: ${grey3};
        margin-top: 32px;
        margin-bottom: 32px;
    }

    h1 {
        font-family: ${boldFontFamily};
        font-size: 32px;
        line-height: 40px;
        border-top: 1px solid ${grey5};
        border-bottom: 1px solid ${grey5};
        padding-bottom: ${({ theme }) => theme.spacing(1.5)};
        padding-top: ${({ theme }) => theme.spacing(1.5)};
        margin-top: 16px;
        margin-bottom: 16px;
    }

    h2 {
        font-family: ${boldFontFamily};
        line-height: 32px;
        font-size: 24px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    img:not(.ghost) {
        width: 100%;
    }

    hr {
        border: none;
        border-top: 1px solid ${grey5};
        margin-top: 32px;
        margin-bottom: 32px;
    }

    a {
        text-decoration: underline;
        text-underline-position: under;
        color: ${grey3};

        :visited {
            color: ${grey3};
        }

        :hover {
            color: ${black} !important;
        }

        cursor: pointer;
    }

    img {
        border-radius: 8px;
    }

    summary {
        font-family: ${boldFontFamily};
    }

    table {
        border-radius: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(2)};
        background: ${white};
        border: 1px solid ${grey5};
        width: 100%;

        tr {
            th {
                text-align: left;
                font-family: ${boldFontFamily};
                vertical-align: top;
                padding-right: ${({ theme }) => theme.spacing(1)};
            }

            td {
                vertical-align: top;
                padding-left: 0;
                padding-right: ${({ theme }) => theme.spacing(1)};
            }

            td:not(:first-child) {
                border-left: 1px solid ${grey5};
                padding-left: ${({ theme }) => theme.spacing(2)};
                padding-right: 0;
            }

            th:not(:first-child) {
                padding-left: ${({ theme }) => theme.spacing(2)};
                padding-right: 0;
            }
        }
    }

    @media (max-width: 768px) {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        td {
            border: none;
            position: relative;
            padding-left: 50%;
            padding-right: 0;
        }

        table {
            width: auto;
            tr {
                td:not(:first-child),
                th:not(:first-child) {
                    border: none;
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: ${({ theme }) => theme.spacing(2)};
                }
            }
        }
    }
`;

const StyledDivForContentBox = styled('div')`
    background: ${grey7};
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: 0 ${({ theme }) => theme.spacing(3)};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};

    p:first-child strong {
        font-size: 18px;
    }
`;

const StyledDivForP2 = styled('div')`
    font-size: 16px;
    line-height: 24px;
    max-width: 50%;
`;

const StyledDivForBody2 = styled('div')`
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};
`;

const StyledDivForCaption = styled('div')`
    font-size: 12px;
    line-height: 20px;
`;

const StyledDivForCloser = styled('div')`
    margin-top: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const StyledDivForLarger = styled('div')<{ ignoreBigContent?: boolean }>`
    width: ${({ ignoreBigContent }) => (ignoreBigContent ? '100%' : '150%')};

    @media (max-width: 768px) {
        width: 100%;
    }
`;

// Provide custom components for markdown elements
interface SwMarkdownProps extends WithPickerProps {
    data: any;
    field: string;
    isEditorial: boolean;
    ignoreBigger?: boolean;
}

const SwMarkdown = WithImagePicker((props: SwMarkdownProps) => {
    const content = props.data[props.field];
    const ignoreBigContent = props.ignoreBigger;

    const markdownStyling = {
        caption: (props) => <StyledDivForCaption {...props} />,
        box: (props) => <StyledDivForContentBox {...props} />,
        closer: () => (
            <StyledDivForCloser>
                <img alt={'ghost'} className={'ghost'} src={GhostGrey} />
            </StyledDivForCloser>
        ),
        bigger: (props) => {
            return <StyledDivForLarger {...props} ignoreBigContent={ignoreBigContent} />;
        },
        p2: (props) => <StyledDivForP2 {...props} />,
        h1: ({ children, ...props }) => (
            <StyledDivForLarger ignoreBigContent={ignoreBigContent}>
                <h1 id={slug(props.children)} {...props}>
                    {children}
                </h1>
            </StyledDivForLarger>
        ),
        h2: ({ children, ...props }) => (
            <h2 id={slug(props.children)} {...props}>
                {children}
            </h2>
        ),
        body2: (props) => <StyledDivForBody2 {...props} />,
        a: (props) => (
            <SwLink href={props.href} noGatsbyLink={true} noNewTab={true} variant={'body2'}>
                {props.children}
            </SwLink>
        )
    };
    useEffect(() => {
        if (props.fileSrc) {
            alert(
                '![text-in-case-it-doesnt-load](' +
                    getImageUrl(props.fileSrc, 1000, ImageAspectRatios.FOUR_PER_THREE) +
                    ')'
            );
        }
    }, [props.fileSrc]);

    return (
        <StyledDiv iseditorial={props.isEditorial ? 'true' : null}>
            <MDX components={markdownStyling}>{content}</MDX>
        </StyledDiv>
    );
});

export default SwMarkdown;
