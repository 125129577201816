import React from 'react';
import { Grid } from '@mui/material';
import { grey5 } from '../../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../../lib/components/bedrock/typography/SwTypography';
import SwLine from '../../SwLine';

const SwAssessmentTooltip = (props: SwAssessmentTooltipProps) => {
    return (
        <Grid container={true}>
            {props.headline && (
                <>
                    <Grid item={true} xs={12}>
                        <SwTypography>{props.headline}</SwTypography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <SwLine color={grey5} spacing={1} />
                    </Grid>
                </>
            )}
            <Grid item={true} xs={12}>
                {props.body}
            </Grid>
        </Grid>
    );
};

interface SwAssessmentTooltipProps {
    headline?: string;
    body: JSX.Element;
    noFooter?: boolean;
}

export default SwAssessmentTooltip;
