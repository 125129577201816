import React from 'react';
import { TypographyProps } from '@mui/material';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { InputTypes } from '../../../lib/enums/InputTypes';
import SwMarkdown from '../Inputs/SwMarkdown';
import SwEditorialTypography from './SwEditorialTypography';

const SwEditableTypography = (props: SwEditableTypographyProps) => {
    switch (props.inputType) {
        case InputTypes.SELECT:
        case InputTypes.SIMPLE_TEXT:
            return (
                <SwTypography component={props.component} variant={props.variant}>
                    {props.children}
                </SwTypography>
            );
        case InputTypes.SIMPLE_TEXT_EDITORIAL:
            return <SwEditorialTypography variant={props.variant}>{props.children}</SwEditorialTypography>;
        case InputTypes.MARKDOWN:
        case InputTypes.MARKDOWN_EDITORIAL:
            return (
                <SwMarkdown
                    data={props.data}
                    field={props.field}
                    ignoreBigger={true}
                    isEditorial={props.inputType === InputTypes.MARKDOWN_EDITORIAL}
                    readOnly={true}
                />
            );
        default:
            return <div>Not implemented</div>;
    }
};

interface SwEditableTypographyProps {
    variant?: any; // enum material ui
    data?: any;
    field?: string;
    inputType: string;
    component?: TypographyProps['component'];
    options?: any;
    children: any;
    onClick?: (event) => void;
    prompt?: string;
    isMultiline?: boolean;
    rowsMax?: number;
    charactersMax?: number;
    selectedId?: number;
}

export default SwEditableTypography;
