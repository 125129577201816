import React from 'react';
import { Grid } from '@mui/material';
import { grey5 } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { isDefined, isNotDefined } from '../../../lib/helpers/object';
import Project from '../../../lib/types/project';
import SwLine from '../SwLine';
import SwCriteriaMark from './SwCriteriaRowMark';
import SwAssessmentTooltip from './assessment/SwAssessmentTooltips';

interface SectionAssessmentBarriersTooltipProps {
    project: Project;
}
export const isScoreUnavailable = (project: Project) =>
    [
        project.technicalBarriers,
        project.economicalBarriers,
        project.financialBarriers,
        project.politicalBarriers,
        project.socialBarriers
    ].some((barrier) => isNotDefined(barrier));

const SectionAssessmentBarriersTooltip = (props: SectionAssessmentBarriersTooltipProps) => {
    const { project } = props;

    return (
        <SwAssessmentTooltip
            body={
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={12}>
                                <SwTypography>
                                    The total project barrier score is available when all types of barrier are provided.
                                    It is a composite score based on the 5 barriers assessed.
                                </SwTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Grid container={true}>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwCriteriaMark mark={project.technicalBarriers} title={'Technical'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwCriteriaMark mark={project.financialBarriers} title={'Financial'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwCriteriaMark mark={project.socialBarriers} title={'Social'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwCriteriaMark mark={project.politicalBarriers} title={'Political'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwCriteriaMark mark={project.economicalBarriers} title={'Economical'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwLine color={grey5} spacing={0.5} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {isDefined(project.barriersSummary) ? (
                        <Grid item={true} xs={12}>
                            <SwTypography>{project.barriersSummary}</SwTypography>
                        </Grid>
                    ) : null}
                </Grid>
            }
            headline={''}
            noFooter={true}
        />
    );
};

export default SectionAssessmentBarriersTooltip;
