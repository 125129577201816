import React from 'react';
import { Typography } from '@mui/material';
import SwBulletText from '../../components/v1/SwBulletText';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';
import { isDefined } from '../../lib/helpers/object';
import { EvaluationStandardKeys } from '../../lib/types/project';
import { TagTypes } from './TagTypes';

export const PROJECT_IMPACT_HEADLINE =
    'Our atmosphere is like a bathtub, which we have been filling for decades with greenhouse gasses. Each ton of CO2e in the tub increases the global temperature and affects the climate.';
export const PROJECT_IMPACT_BODY = (
    <>
        <SwTypography>
            In order to prevent our bathtub from spilling - which would mean reaching a +2°C temperature increase - 3
            types of actions exist:
        </SwTypography>
        <SwBulletText variant={'body2'}>
            Reduction: These projects reduce emissions, closing the tap and slowing down the filling of the tub (e.g.
            switching to more efficient fuels){' '}
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Avoidance: These projects preventing future emissions, stopping a new tap from being opened (e.g. protecting
            forests and peatlands so that they don’t start releasing CO2)
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Removal: These projects remove and capture greenhouse gasses, emptying the bathtub (e.g. reforestation and
            direct air capture technology)
        </SwBulletText>
    </>
);

export const PROJECT_LOCATION_BODY = (
    <SwTypography>
        Solutions can be deployed in a large range of locations, from a micro-regions to entire sections of a continent.
        You might want to focus your contributions on specific areas to maximize local socioeconomic impacts or
        contribute where your activities are based.
    </SwTypography>
);

export const PROJECT_SOLUTION_BODY = (
    <SwTypography>
        Solutions are different ways we can reduce, avoid and remove our emissions. They vary in terms of cost, level of
        maturity, potential to scale, and other factors. To make comparisons easier, Sweep groups solutions into
        categories such as Forests, Geoengineering or Buildings. Climate solutions are numerous: to learn more about
        what can be done, explore our catalog.
    </SwTypography>
);

export const PROJECT_EVALUATION_HEADLINE =
    'Various international and regional standards rely on established methodologies, dedicated processes and third-party verifications to guarantee that a project delivers on its promises.';
export const PROJECT_EVALUATION_BODY = (
    <>
        <SwBulletText variant={'body2'}>
            Certified: Standards, such as Gold Standard or Verra, are listed for maximal transparency
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Not certified yet: The process to be certified is expensive and cumbersome, and ad-hoc methodologies might
            not exist for specific projects, preventing them from being certified. Sweep promotes other projects after a
            necessary due diligence, bringing more projects to a wider audience while also offering opportunities to
            develop and tailor new projects according to your own needs.
        </SwBulletText>
    </>
);

export const PROJECT_CAPACITY_HEADLINE =
    'Capacity is the projected volume of tCO2e reduced or removed during the crediting period. This indicates the projected net impact of a project after adjusting for potential negative impacts and other externalities. This is key to assessing the current scale of a project and can give a quick estimate of what the considered volumes represent at the project scale.';
export const PROJECT_CAPACITY_BODY = (
    <SwTypography>
        Capacity does not reflect the available quantity of credits as some of them can already be sold.
    </SwTypography>
);

export const PROJECT_CREDITING_HEADLINE =
    'Certified projects are eligible to generate carbon credits over a certain period of time. This crediting period gives a good estimate of the progress of a project.';
export const PROJECT_CREDITING_BODY = (
    <>
        <SwBulletText variant={'body2'}>
            Older crediting periods means that projects have delivered their promises and that third-party auditors have
            recognized the climate impact
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Crediting periods covering coming years may have already been audited for a fraction of the volumes. The
            rest will be generated along future audits.
        </SwBulletText>
    </>
);

export const PROJECT_COBENEFITS_BODY = (
    <SwTypography>
        Projects often have positive side effects beyond reducing, removing, or avoiding emissions. For example,
        projects might reduce waste, protect biodiversity, or support indigenous people. These co-benefits are modelled
        after the UN Sustainable Development Goals (UN SDGs).
    </SwTypography>
);

export const PROJECT_DURABILITY_BODY = (
    <SwTypography>
        Durability only applies to to removal solutions. It indicates for how long the carbon will be removed from the
        atmosphere - the longer this is the more desirable. For example, storing carbon as mineral underground is more
        durable than in a tree, making it a less risky and longer term solution.
    </SwTypography>
);

export const PROJECT_ADDITIONALITY_HEADLINE =
    'Additionality reveals the impact of your financial support. It indicates what percentage of the global project budget is coming from contributions.';
export const PROJECT_ADDITIONALITY_BODY = (
    <>
        <SwBulletText variant={'body2'}>
            High additionality means most of the project revenue relies on contributions, and they would likely not be
            able to happen without this financing.
        </SwBulletText>
        <SwBulletText variant={'body2'}>Low additionality projects are less reliant on contributions.</SwBulletText>
        <SwBulletText variant={'body2'}>
            Other additionality criteria, such as policy or regulatory additionality, are tackled through certifications
            and not assessed here{' '}
        </SwBulletText>
    </>
);

export const PROJECT_BARRIER_BODY = (
    <SwTypography>
        Barriers are the different hurdles a project might need to overcome. Barriers can give you a sense for the
        challenges a project faces, but also how it can add deeper value and create change. We differentiate five types
        of barriers: economic, social, institutional, technical and financial.
    </SwTypography>
);

export const DASH_EMISSION_SCOPE_HEADLINE = 'Breakdown of your measured emissions per scope';
export const DASH_EMISSION_SCOPE_BODY: JSX.Element = (
    <>
        <Typography variant={'caption'}>In tonnes of CO2 equivalent</Typography>
        <SwBulletText variant={'caption'}>
            Scope 1 emissions are the direct emissions that your company activities create.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Scope 2 emissions are indirect emissions that come from the production of the electricity or heat you use.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Scope 3 emissions are the indirect emissions that come from all the other activities in which you’re
            engaged.
        </SwBulletText>
    </>
);

export const DASH_EMISSION_TIME_HEADLINE = 'Evolution of your measured emissions over time.';
export const DASH_EMISSION_TIME_BODY: JSX.Element = (
    <Typography variant={'caption'}>Monthly breakdown of your measured emissions</Typography>
);

export const DASH_EMISSION_CATEGORY_HEADLINE = 'Breakdown of your measured emissions per category.';
export const DASH_EMISSION_CATEGORY_BODY: JSX.Element = (
    <Typography variant={'caption'}>
        Categories can change depending on the type of reporting standard selected in the filter at the top of the
        dashboard. You can for example display the Green House Gaz protocol categories.
    </Typography>
);

export const DASH_EMISSION_SUB_CAT_HEADLINE = 'Fine grained breakdown of your measured emissions.';
export const DASH_EMISSION_SUB_CAT_BODY: JSX.Element = (
    <Typography variant={'caption'}>
        Categories can change depending on the type of reporting standard selected in the filter at the top of the
        dashboard. You can for example display the Green House Gaz protocol categories.
    </Typography>
);

export const DASH_EMISSION_GROUP_HEADLINE = 'Measured emissions broken down by group.';
export const DASH_EMISSION_GROUP_BODY: JSX.Element = (
    <Typography variant={'caption'}>Measured emissions broken down by group in your organization tree. </Typography>
);

export const SOLUTION_HEADLINE = 'Solutions are different ways we can reduce and remove our global emissions.';
export const SOLUTION_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            There are a lot of different ways to reduce and remove greenhouse gases. And every type of solution comes
            with its own costs, level of maturity or potential to scale, for example.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            To make quick comparisons easier, Sweep groups solutions into categories such as Forests, Geoengineering or
            Buildings.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            To learn more about the different types of solutions, read our Guide to Climate Solutions.
        </SwBulletText>
    </>
);

export const SOLUTION_DETAILS_HEADLINE = (label?: string): string => {
    return isDefined(label) ? 'Solution: ' + label : 'Solution not provided';
};

export const SOLUTION_DETAILS_BODY = (label: string) => {
    switch (label) {
        case TagTypes.FOREST.label: {
            return (
                <SwTypography>
                    It doesn't get easier than that. Restoring and protecting our environment using what nature does
                    best: using CO2 to grow trees. These are also referred to as green carbon solutions
                </SwTypography>
            );
        }
        case TagTypes.WATER.label: {
            return (
                <SwTypography>
                    Trees do not have a monopoly on photosynthesis. Water ecosystems absorb tonnes of CO2 too. More
                    importantly, they have the ability to store carbon virtually forever. Solutions using water
                    ecosystems are sometimes called blue carbon solutions.
                </SwTypography>
            );
        }
        case TagTypes.SOILS.label: {
            return (
                <SwTypography>
                    Dirt can be gold. When we feed our soils with trapped greenhouse gas, something almost magical
                    happens: They store the carbon. And in turn they retain water better and grow plants faster. As a
                    result, the soil becomes more resistant to erosion and desertification. And whether it is for
                    farming or forests - we will always need better soil in the future.
                </SwTypography>
            );
        }
        case TagTypes.BIOMASS.label: {
            return (
                <SwTypography>
                    Plants are great at capturing and storing CO2. Biomass solutions take this beyond just growing
                    forests, by using them as a material to create new things. Think wooden houses, or a heating system
                    using organic waste. Rather than create more emissions with new stuff, we remove them. Clearly a
                    win-win solution.
                </SwTypography>
            );
        }
        case TagTypes.DAC.label: {
            return (
                <SwTypography>
                    High-tech to fight high temperatures: This is mankind putting it’s potential to push the envelope to
                    best use. Direct air capture refers to industrial solutions currently in development that aim to
                    suck the CO2 straight out of the atmosphere, before storing it underground, or putting it to use in
                    commercial products.
                </SwTypography>
            );
        }
        case TagTypes.GEOENGINEERING.label: {
            return (
                <SwTypography>
                    What if we could use the natural methods our planet had developed to get rid of the greenhouse
                    gases, and accelerate and enhance them? Well this is what geoengineering is about. Whether it is in
                    the clouds, on the mountains, or in the sea, geoengineering is where the innovation is happening.
                </SwTypography>
            );
        }
        case TagTypes.INDUSTRY.label: {
            return (
                <SwTypography>
                    It is not for nothing that we use pictures of fuming plants to illustrate emissions. And there are
                    many ways to decrease industrial footprint, from improving the efficiency to redesigning processes.
                </SwTypography>
            );
        }
        case TagTypes.BUILDINGS.label: {
            return (
                <SwTypography>
                    We all know that our building’s operation consumes a lot, just from looking at our utility bills.
                    What we often overlook though, is the impact of the construction of the building itself. From using
                    better materials to turning off the lights more often, there are a lot of solutions to improve our
                    emissions.
                </SwTypography>
            );
        }
        case TagTypes.ENERGY.label: {
            return (
                <SwTypography>
                    The largest chunk of CO2 emissions come from burning fossil fuels to generate energy. By
                    decarbonising electricity and heat production, we can unlock massive emission reduction potential.
                    Time to stop heating the planet when heating the home.
                </SwTypography>
            );
        }
        case TagTypes.TRANSPORT.label: {
            return (
                <SwTypography>
                    Approximately 15% of all our emissions come from transportation – both of ourselves and our goods.
                    From optimised freight routes to biking lanes, to connected trucks and optimised public transports,
                    the solutions are abundant and relatively easy to implement.
                </SwTypography>
            );
        }
        case TagTypes.LAND_USE.label: {
            return (
                <SwTypography>
                    Land use includes mostly agriculture and forestry, while taking into account the conversion of land
                    to one ‘use’ to another. Land use solutions usually have double potential. Not only can we improve
                    efficiency, reduce waste and decarbonise most processes. We can also capitalise on nature's own
                    ability to absorb CO2 by regenerating ecosystems and reducing the need to convert more land in the
                    first place.
                </SwTypography>
            );
        }
        case TagTypes.CONSERVATION.label: {
            return (
                <SwTypography>
                    Natural ecosystems have been removing carbon from the atmosphere well before we evolved into homo
                    sapiens, and if we let them, they will continue to do so virtually forever. Protecting and
                    conserving these ecosystems ensure that the carbon stays where it should and that more CO2 can be
                    removed from the atmosphere, while also protecting biodiversity. A pretty neat solution.
                </SwTypography>
            );
        }
        case TagTypes.COMMUNITY.label: {
            return (
                <SwTypography>
                    Time to pass it on and educate everyone about the importance of reducing our emissions and creating
                    a better future. After all, this is an all hands on deck situation. And there are numerous smart
                    solutions out there that aim to get everyone on board.
                </SwTypography>
            );
        }
    }
};

export const LOCATION_HEADLINE = 'Location indicates where a project is removing or reducing the carbon.';

export const LOCATION_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            The location refers to where the project activity is taking place, meaning where the carbon is reduced or
            removed. This is not always the same as the company address of the project owner.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Some project owners might also have activities in several locations (for example forests in different
            countries). In that case, every location will get its own project page.
        </SwBulletText>
    </>
);

export const STORAGE_HEADLINE = 'Storage, in contrast to Location, indicates where the project is storing the carbon.';

export const STORAGE_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            Storage indicates where a project is storing the removed carbon. For some projects this might be the same as
            Location.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Storage only applies to Removal projects. A Reduction project ensures the carbon isn’t emitted in the first
            place, and therefore doesn’t need to be stored.
        </SwBulletText>
    </>
);

export const EVALUATION_HEADLINE = 'Evaluation lists any third-party verification.';

export const EVALUATION_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            There are various international and regional standards for carbon projects, such as Gold Standard or Verra.
            We list these third-party verifications.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Sweep also reviews every project independently from these labels. Therefore, if a project doesn’t have
            third-party verification, we label it with Sweep.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Third-party verifications can be a great way to get assurances that a project delivers on its promises. It
            can also be expensive and cumbersome for smaller projects that still make a big difference. Which is why
            Sweep is applying its own filter on top of traditional verification and will investigate ways to bring more
            projects to a wider audience.
        </SwBulletText>
    </>
);

export const EVALUATION_DETAILS_HEADLINE = (categoryLabel: string, evaluationStandardLabel?: string): string => {
    const isCertified = categoryLabel.startsWith('Certified');

    return `${
        evaluationStandardLabel === 'No official carbon standard'
            ? 'Not certified'
            : isCertified
              ? 'Certified'
              : 'Alternative'
    } • ${evaluationStandardLabel}`;
};

export const EVALUATION_DETAILS_BODY = (evaluationStandardKey: EvaluationStandardKeys) => {
    switch (evaluationStandardKey) {
        case EvaluationStandardKeys.SWEEP: {
            return (
                <>
                    <SwBulletText variant={'caption'}>
                        This project has been assessed directly by Sweep’s climate experts to ensure impact and
                        performance.
                    </SwBulletText>
                    <SwBulletText variant={'caption'}>
                        Third-party standards can be a great way to get assurances that a project delivers on its
                        promises. It can also be expensive, cumbersome and sometimes not available at all because the
                        project is first of its kind. Which is why Sweep is applying its own filter in addition to the
                        traditional carbon standards and investigates ways to bring more projects to a wider audience.
                    </SwBulletText>
                </>
            );
        }
        case EvaluationStandardKeys.VERA_VCS: {
            return (
                <Typography variant={'caption'}>
                    Verra defined VCS (short for Voluntary Carbon Standard), which is the most widely used global
                    standard for project assessments.
                </Typography>
            );
        }
        case EvaluationStandardKeys.GOLD_STANDARD: {
            return (
                <Typography variant={'caption'}>
                    Championed by WWF, this standard applies to projects in developing countries involving the local
                    population and presenting co-benefits.
                </Typography>
            );
        }
        case EvaluationStandardKeys.PLAN_VIVO: {
            return (
                <Typography variant={'caption'}>
                    This standard certifies that projects bring co-benefits, including poverty alleviation, conservation
                    and restoration of degraded ecosystems and of biodiversity, or climate resilience.
                </Typography>
            );
        }
        case EvaluationStandardKeys.CLIMATE_ACTION_RESERVE: {
            return (
                <Typography variant={'caption'}>
                    To be included in the CAR registry, a carbon project needs to comply with Verra (VCS) standards as
                    well as being located in the US and Mexico.
                </Typography>
            );
        }
        case EvaluationStandardKeys.REDD_PLUS: {
            return (
                <Typography variant={'caption'}>
                    REDD+ (short for Reducing Emissions from Deforestation and forest Degradation) is a UN programme for
                    developing countries, attributing carbon credit value to forests projects.
                </Typography>
            );
        }
        case EvaluationStandardKeys.SOCIAL_CARBON_STANDARD: {
            return (
                <Typography variant={'caption'}>
                    SCS ensures continued tracking of social and environmental performance to guarantee the project
                    brings additionality.
                </Typography>
            );
        }
        case EvaluationStandardKeys.CLIMATE_COMMUNITY: {
            return (
                <Typography variant={'caption'}>
                    This label assesses land use projects on their impact on local communities and biodiversity.
                </Typography>
            );
        }
        case EvaluationStandardKeys.W_PLUS_STANDARD: {
            return (
                <Typography variant={'caption'}>
                    This label certifies projects that champion women, benefiting them socioeconomically and developing
                    their autonomy.
                </Typography>
            );
        }
        case EvaluationStandardKeys.WATER_BENEFITS_STANDARD: {
            return (
                <Typography variant={'caption'}>
                    Developed by Gold Standard, this label assesses environmental and socio-economic benefits of
                    sustainable water projects worldwide.
                </Typography>
            );
        }
        case EvaluationStandardKeys.FAIRTRADE: {
            return (
                <Typography variant={'caption'}>
                    Developed along Gold Standard, this label supports smaller producers and rural communities to
                    generate carbon credits.
                </Typography>
            );
        }
        case EvaluationStandardKeys.UNFCCC: {
            return (
                <Typography variant={'caption'}>
                    This is the classic. The most official of official standards. UNFCCC stands for The United Nations
                    Framework Convention on Climate Change. It’s credits issued under the international treaty amongst
                    197 countries that seek to reduce carbon emissions.
                </Typography>
            );
        }
        case EvaluationStandardKeys.PURO: {
            return (
                <Typography variant={'caption'}>
                    Puro is a new label that focuses on removal solutions. Like any established standard, they work with
                    approved methodologies, third-party verification, and their own registry.
                </Typography>
            );
        }
        case EvaluationStandardKeys.AMERICAN_CARBON_REGISTRY: {
            return (
                <Typography variant={'caption'}>
                    The ACR is the first private voluntary greenhouse gas registry in the world. The associated American
                    Carbon Registry Standard outlines the eligibility requirements for registration of project-based
                    carbon offsets and includes requirements for methodology approval, project validation and
                    verification, and other procedural requirements and information on the general use of the ACR.
                </Typography>
            );
        }
    }
};

export const VOLUME_HEADLINE =
    'Volume indicates the projected capacity of reduced or removed tCO2e for the current year.';

export const VOLUME_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            Volume indicates the projected volume of reduced or removed in tCO2e for the current year. Year counted as
            of Launch date
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Volume indicates the projected net emission reduction or removal capacity of a project, after removing the
            potential project negative footprint and other externalities.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Volume is one of the key factors to assess the current scale of a project and is to be compared with the
            target volume to assess the progress of said project
        </SwBulletText>
    </>
);

export const DURABILITY_HEADLINE = 'Durability indicates for how long the carbon will be removed.';

export const DURABILITY_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            Not all removal solutions sequester the CO2 for the same period of time.
        </SwBulletText>
        <SwBulletText variant={'body2'}>A higher durability, meaning longer storage, is more desirable.</SwBulletText>
        <SwBulletText variant={'body2'}>
            For example, storing carbon as mineral underground is more durable, meaning both less risky and longer term,
            than as a tree.
        </SwBulletText>
    </>
);

export const ADDITIONALITY_HEADLINE = 'Additionality reveals the impact of your financial support.';

export const ADDITIONALITY_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>At Sweep, additionality refers to financial additionality.</SwBulletText>
        <SwBulletText variant={'body2'}>
            It indicate what percentage of the global project budget is coming from contributions.
        </SwBulletText>
        <SwBulletText variant={'body2'}>We score it from low to high.</SwBulletText>
    </>
);

export const PRICE_HEADLINE = 'Price lists the current price per tCO2 in €, as sold on Sweep.';

export const PRICE_BODY: JSX.Element = (
    <SwBulletText variant={'caption'}>The price per tCO2 is set by the project directly.</SwBulletText>
);

export const BARRIERS_HEADLINE = 'Barriers are different types of hurdles that a project might need to overcome.';

export const BARRIERS_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            Barriers can give you a sense for what challenges the project might face, but also how it can add deeper
            value and create change.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            We differentiate five types of barriers: economic, social, institutional, technical and financial.
        </SwBulletText>
    </>
);

export const POSITIVE_EFFECTS_HEADLINE = 'Co-benefits list any likely positive side effects of the project.';

export const POSITIVE_EFFECTS_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'body2'}>
            Positive side effects are any additional benefits a project has, besides dealing with our carbon emissions.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            For example, some projects might reduce waste, increase biodiversity, or support marginalised people.
        </SwBulletText>
        <SwBulletText variant={'body2'}>
            Positive effects are modelled after the UN Sustainable Development Goals.
        </SwBulletText>
    </>
);

export const VOLUME_BOUGHT_HEADLINE = 'Volume bought shows you your total tCO2e you purchased.';

export const VOLUME_BOUGHT_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            Volume matters if you’re working towards your net zero, or if you need to account for a certain amount of
            contributions.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            However, keep in mind that not all tCO2e are equal, and quantity should not be your only focus.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            For example, some tCO2e are more effective than others, or better at championing future innovation.
        </SwBulletText>
    </>
);

export const AVERAGE_PRICE_HEADLINE = 'The average price per tCO2e equivalent.';

export const AVERAGE_PRICE_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            Price per tCO2e can be helpful to assess your spend for your climate programme.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            If you want to maximise impact, cheaper is not necessarily better though. The world needs to develop and
            scale a broad portfolio of global solutions to get to net zero.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Some of these solutions will be more expensive than others, based on factors such as maturity and location,
            but no less important.
        </SwBulletText>
    </>
);

export const SPEND_HEADLINE = 'Spend indicates your total contribution spend for the selected time period.';

export const SPEND_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            The total spend on your climate programme is a simple and strong indicator for your commitment to a net zero
            world.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            This can be an equally or even more powerful metric than your tCO2e purchased. After all, it’s easy to buy
            high volumes at (too) cheap prices. It is harder to run a really impactful programme that makes a real
            difference.
        </SwBulletText>
    </>
);

export const FUNDING_THE_FUTURE_HEADLINE =
    'Funding the future helps assess your long term impact by helping projects improve over time';

export const FUNDING_THE_FUTURE_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            To achieve the world’s climate goals, we need to innovate and scale future solutions.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Some projects may have small capacity or high prices today, but the potential to substantially scale and
            come down in price over time.
        </SwBulletText>
    </>
);

export const LONG_HAUL_FLIGHT_HEADLINE =
    'Long haul flights compares the tCO2e of your total contributions to the emissions of a Paris to New York flight';

export const LONG_HAUL_FLIGHT_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            We took 2.5 tCO2e for an average economy class Paris to New York flight of 7419 km.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            Let’s not even talk about business class, that would be 4 times this number.
        </SwBulletText>
    </>
);

export const TONS_OF_ICE_HEADLINE =
    'Tons of ice saved compares the tCO2e of your total contributions to the avoided melting potential of ice.';

export const TONS_OF_ICE_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>Every 1 t of CO2e ultimately melts about 650 t of glacial ice.</SwBulletText>
        <SwBulletText variant={'caption'}>
            With the knock on effects of climate change, things might be worse. So if you like your cocktails cool, keep
            that carbon in the ground.
        </SwBulletText>
    </>
);

export const KM_IN_CAR_HEADLINE =
    'Kilometers driven in a car compares the tCO2e of your total contributions to the emissions of a not-so-green car.';

export const KM_IN_CAR_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>The average car emits 1 tCO2e for every 4950 km driven.</SwBulletText>
        <SwBulletText variant={'caption'}>
            Did you try an electric car lately? Or use a bike to burn calories, rather than dirty fossil fuel.
        </SwBulletText>
    </>
);

export const ROCKET_LAUNCH_HEADLINE =
    'Rocket launches compares the tCO2e of your total contributions to the emissions of a rocket launch.';

export const ROCKET_LAUNCH_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>A rocket launch releases a jaw-dropping 336.5 tCO2e.</SwBulletText>
        <SwBulletText variant={'caption'}>
            It will certainly nice to discover alien friends, but let’s also make sure our species survives to see that
            day.
        </SwBulletText>
    </>
);

export const HOURS_OF_HEATING_HEADLINE =
    'Hours of heating compares the tCO2e of your total contributions to the emissions of an average space.';

export const HOURS_OF_HEATING_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            To heat an average space of 80 square meters in Europe, you’ll emit approximately 0.02 tC02e.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            It’s nice to keep warm, but make sure to use renewable energy to not overheat us all.
        </SwBulletText>
    </>
);

export const COWS_FART_HEADLINE =
    'Cow farts compares the tCO2e of your total contributions to the emissions of a cow farting.';

export const COWS_FART_BODY: JSX.Element = (
    <>
        <SwBulletText variant={'caption'}>
            Our very sophisticated calculation revealed that approximately 2500 cow farts result in 1 tCO2e.
        </SwBulletText>
        <SwBulletText variant={'caption'}>
            However, as it turns out, cow burps are even worse than farts. Masks (!) could reduce cattle methane
            emissions by more than 60%.
        </SwBulletText>
    </>
);
