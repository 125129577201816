import React, { useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Box, Grid, styled } from '@mui/material';
import { grey6 } from '../../../lib/components/bedrock/SwColors';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import Project from '../../../lib/types/project';
import SwCarousel from '../SwCarousel';
import SwContainer from '../SwContainer';
import SwEndOfSection from '../SwEndOfSection';
import SwSubMenu from '../website/SwSubMenu';
import SwWebsiteFooter from '../website/SwWebsiteFooter';
import SectionAssessment from './SectionAssessment';
import SectionCompanyDescription from './SectionCompanyDescription';
import SectionCreatePortfolio from './SectionCreatePortfolio';
import SectionHeader from './SectionHeader';

const StyledContainer = styled(SwContainer)`
    & > div {
        width: 100%;
    }
`;

enum Sections {
    COMPANY = 'company',
    ASSESSMENT = 'assessment'
}

const SwProjectPage = (props: SwProjectProps) => {
    const companyRef = useRef(null);
    const assessmentRef = useRef(null);

    const [isVisibleOb, setIsVisibleOb] = useState(null);

    const isVisible = (inView: boolean, entry: any) => {
        setIsVisibleOb({ inView: inView, entry: entry });
    };

    const scrollTo = (index: number) => {
        switch (index) {
            case 0: {
                const companyY = companyRef?.current.getBoundingClientRect().top + window.pageYOffset - 64 - 52;
                window.scrollTo({ top: companyY, behavior: 'smooth' });
                break;
            }
            case 1: {
                const assessmentY = assessmentRef?.current.getBoundingClientRect().top + window.pageYOffset - 64 - 52;
                window.scrollTo({ top: assessmentY, behavior: 'smooth' });
                break;
            }
        }
    };

    const handleChangeTab = (_, newTabValue: number) => {
        scrollTo(newTabValue);
    };

    const goToAssessment = () => handleChangeTab(null, 1);

    return (
        <>
            <SwContainer withMaxWidth={true}>
                <Box>
                    <SwSpacer direction={'vertical'} spacing={4} />
                    <SectionHeader goToAssessment={goToAssessment} project={props.project} />
                </Box>
            </SwContainer>
            <SwContainer withMaxWidth={true}>
                <SwSpacer direction={'vertical'} spacing={4} />
            </SwContainer>

            <SwSubMenu
                isVisibleEntry={isVisibleOb}
                refs={[companyRef, assessmentRef]}
                tabs={['About', 'Assessment']}
                tabsId={[Sections.COMPANY, Sections.ASSESSMENT]}
                withMaxWidth={true}
            />

            <SwCarousel data={props.project} field={'heroImages'} images={props.project.heroImages} index={0} />

            <SwContainer withMaxWidth={true}>
                <SwSpacer direction={'vertical'} spacing={9} />
            </SwContainer>
            <StyledContainer withMaxWidth={true}>
                <InView as={'div'} id={Sections.COMPANY} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                    <div ref={companyRef}>
                        <Grid container={true}>
                            <SectionCompanyDescription project={props.project} />
                        </Grid>
                    </div>
                </InView>
            </StyledContainer>
            <SwEndOfSection />
            <StyledContainer background={grey6} withMaxWidth={true}>
                <InView as={'div'} id={Sections.ASSESSMENT} rootMargin={'-128px 0px 0px 0px'} onChange={isVisible}>
                    <div ref={assessmentRef}>
                        <SectionAssessment compactAssessment={false} project={props.project} showDescription={true} />
                    </div>
                </InView>
            </StyledContainer>
            <SwEndOfSection />
            <StyledContainer withMaxWidth={true}>
                <SectionCreatePortfolio />
            </StyledContainer>
            <SwSpacer direction={'vertical'} spacing={3} />
            <SwWebsiteFooter newsletterForm={props.pageData.newsletterForm} />
        </>
    );
};

interface SwProjectProps {
    project: Project;
    isPreview?: boolean;
    pageData: any;
}

export default SwProjectPage;
