import React, { useState } from 'react';
import { Box, Grid, styled } from '@mui/material';
import { random } from 'lodash';
import { zIndexes } from '../../lib/enums/zIndexes';
import { getImageUrl } from '../../lib/helpers/image';
import { WithImagePicker, WithPickerProps } from './common/WithPicker';

const StyledBox = styled(Box)`
    height: ${(props) => (props.height ? props.height + 'px' : '670px')};
    overflow: hidden;
`;

const StyledBox2 = styled(Box)`
    position: relative;
`;

const StyledImg = styled('img')<{ notFullWidth?: boolean }>`
    width: 100%;
    object-fit: cover;
    height: ${(props) => (props.height ? props.height + 'px' : '670px')};

    border-radius: ${({ notFullWidth }) => (notFullWidth ? '8px' : '0')};
`;

const StyledGridOverlay = styled(Grid)`
    margin-top: ${({ theme }) => theme.spacing(4)};
    margin-right: ${({ theme }) => theme.spacing(4)};
    z-index: ${zIndexes.TOOLTIP};
    position: absolute;
`;

const defaultImages = [
    '2f0cc8fa-e38c-4042-a9c5-8eb971481203.jpeg',
    'b5983489-b6cf-49af-8c85-7e665871580d.jpeg',
    '7099910c-b8e1-4388-8b47-056d90781db5.jpeg'
];

const SwCarousel = WithImagePicker((props: SwCarouselProps) => {
    const [imgSrc] = useState(
        props.images && props.images.length > props.index && props.images[props.index] !== ''
            ? props.images[props.index]
            : defaultImages[random(0, defaultImages.length - 1)]
    );

    return (
        <StyledBox height={props.height}>
            <StyledBox2>
                {props.children && (
                    <StyledGridOverlay container={true}>
                        <Grid item={true} md={9} xs={1} />
                        <Grid item={true} md={3} xs={11}>
                            {props.children}
                        </Grid>
                    </StyledGridOverlay>
                )}
                <StyledImg alt={'hero image'} notFullWidth={props.notFullWidth} src={getImageUrl(imgSrc)} />
            </StyledBox2>
        </StyledBox>
    );
});

interface SwCarouselProps extends WithPickerProps {
    images: string[];
    index: number;
    data: any;
    field: string;
    children: any;
    height?: number;
    notFullWidth?: boolean;
}

export default SwCarousel;
