import { ArrowRight, Leaf, MapPin, Medal, ThermometerSimple } from '@phosphor-icons/react';
import React from 'react';
import { Box, styled } from '@mui/material';
import { grey5 } from '../../../../lib/components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '../../../../lib/components/bedrock/button/SwButton';
import SwLine from '../../../../lib/components/bedrock/layout/SwLine';
import SwSpacer from '../../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../../lib/components/bedrock/typography/SwTypography';
import { isDefined } from '../../../../lib/helpers/object';
import Project from '../../../../lib/types/project';

const StyledCard = styled('div')`
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2)};
`;

interface SwAssessmentCardProps {
    project: Project;
    goToAssessment: () => void;
}

const SwAssessmentCard: React.FunctionComponent<SwAssessmentCardProps> = (props) => {
    const { project, goToAssessment } = props;

    return (
        <StyledCard>
            <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box alignItems={'center'} display={'flex'} width={'50%'}>
                        <ThermometerSimple />
                        <SwSpacer direction={'horizontal'} spacing={1} />
                        <SwTypography>Climate impact</SwTypography>
                    </Box>
                    <Box width={'50%'}>
                        <SwTypography bold={true}>
                            {isDefined(project.familyCategory) ? project.familyCategory : 'N/A'}
                        </SwTypography>
                    </Box>
                </Box>

                <SwLine color={grey5} direction={'horizontal'} spacingBottom={1.5} spacingTop={1.5} />

                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box alignItems={'center'} display={'flex'} width={'50%'}>
                        <Leaf />
                        <SwSpacer direction={'horizontal'} spacing={1} />
                        <SwTypography>Solution</SwTypography>
                    </Box>
                    <Box width={'50%'}>
                        <SwTypography bold={true}>
                            {isDefined(project.solutionCategory) ? project.solutionCategory : 'N/A'}
                        </SwTypography>
                    </Box>
                </Box>

                <SwLine color={grey5} direction={'horizontal'} spacingBottom={1.5} spacingTop={1.5} />

                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box alignItems={'center'} display={'flex'} width={'50%'}>
                        <MapPin />
                        <SwSpacer direction={'horizontal'} spacing={1} />
                        <SwTypography>Location</SwTypography>
                    </Box>
                    <Box width={'50%'}>
                        <SwTypography bold={true}>
                            {isDefined(project.country?.name) ? project.country.name : 'N/A'}
                        </SwTypography>
                    </Box>
                </Box>

                <SwLine color={grey5} direction={'horizontal'} spacingBottom={1.5} spacingTop={1.5} />

                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box alignItems={'center'} display={'flex'} width={'50%'}>
                        <Medal />
                        <SwSpacer direction={'horizontal'} spacing={1} />
                        <SwTypography>Evaluation</SwTypography>
                    </Box>
                    <Box width={'50%'}>
                        <SwTypography bold={true}>
                            {isDefined(project.evaluationStandard?.label) ? project.evaluationStandard.label : 'N/A'}
                        </SwTypography>
                    </Box>
                </Box>

                <SwLine color={grey5} direction={'horizontal'} spacingBottom={1.5} spacingTop={1.5} />

                <Box display={'flex'}>
                    <SwButton endIcon={<ArrowRight />} kind={SwButtonKind.Secondary} onClick={goToAssessment}>
                        Learn more
                    </SwButton>
                </Box>
            </Box>
        </StyledCard>
    );
};

export default SwAssessmentCard;
