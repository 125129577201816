import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

export const editorialFontFamily = 'GT-Alpina-Condensed-Bold';

export const useStyles = makeStyles(() => {
    return {
        editorialTypography: {
            '& h1': {
                margin: 0,
                fontWeight: 'bold',
                fontFamily: editorialFontFamily,
                fontSize: '104px',
                lineHeight: '104px',
                '@media (max-width:600px)': {
                    fontSize: '48px',
                    lineHeight: '56px'
                },
                '&.ellipsis': {
                    display: '-webkit-box',
                    maxWidth: '100%',
                    margin: '0 auto',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            },
            '& h2': {
                margin: 0,
                fontWeight: 'bold',
                fontFamily: editorialFontFamily,
                fontSize: '68px',
                lineHeight: '72px',
                '@media (max-width:600px)': {
                    fontSize: '40px',
                    lineHeight: '44px'
                },
                '&.ellipsis': {
                    display: '-webkit-box',
                    maxWidth: '100%',
                    margin: '0 auto',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            },
            '& h3': {
                margin: 0,
                fontWeight: 'bold',
                fontFamily: editorialFontFamily,
                fontSize: '42px',
                lineHeight: '40px',
                '&.ellipsis': {
                    display: '-webkit-box',
                    maxWidth: '100%',
                    margin: '0 auto',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            },
            '& h4': {
                margin: 0,
                fontWeight: 'bold',
                fontFamily: editorialFontFamily,
                fontSize: '32px',
                lineHeight: '38px',
                '@media (max-width:600px)': {
                    fontSize: '22px',
                    lineHeight: '28px'
                },
                '&.ellipsis': {
                    display: '-webkit-box',
                    maxWidth: '100%',
                    margin: '0 auto',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            }
        }
    };
});

const SwEditorialTypography = (props: SwEditorialTypographyProps) => {
    const Component = props.variant;
    const classes = useStyles(props);

    if (props.isEditorial === false) {
        return (
            <Typography
                className={props.ellipsis ? 'ellipsis' : ''}
                style={props.color ? { color: props.color } : {}}
                variant={props.variant}
            >
                {props.children}
            </Typography>
        );
    } else {
        return (
            <Box className={clsx(classes.editorialTypography)}>
                <Component
                    className={props.ellipsis ? 'ellipsis' : ''}
                    style={props.color ? { color: props.color } : {}}
                >
                    {props.children}
                </Component>
            </Box>
        );
    }
};

interface SwEditorialTypographyProps {
    variant: any;
    children: any;
    color?: string;
    ellipsis?: boolean;
    isEditorial?: boolean;
}

export default SwEditorialTypography;
