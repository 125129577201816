import dayjs from 'dayjs';
import { ObjectTypes } from '../enums/ObjectTypes';
import { Address } from './address';
import Article from './article';
import { Company } from './company';
import Tag from './tag';

export type Mark = 1 | 2 | 3;

export enum EvaluationStandardKeys {
    SWEEP,
    VERA_VCS,
    GOLD_STANDARD,
    PLAN_VIVO,
    CLIMATE_ACTION_RESERVE,
    REDD_PLUS,
    SOCIAL_CARBON_STANDARD,
    CLIMATE_COMMUNITY,
    W_PLUS_STANDARD,
    WATER_BENEFITS_STANDARD,
    FAIRTRADE,
    UNFCCC,
    PURO,
    AMERICAN_CARBON_REGISTRY
}

export type EvaluationStandard = {
    key: EvaluationStandardKeys;
    color: string;
    label: string;
};

export default class Project {
    id: string;
    slug: string;
    name: string;
    shortDescription: string;
    longDescription: string;
    descriptionHeadline: string;
    startAt: string;
    endAt: string;
    addresses: Address[];
    quote: string;
    company: Company;
    heroImages: string[];
    logoProject: string;
    logo: string[];
    tags: Tag[];
    commentThread: any;
    associatedProjects: Project[];
    academyArticles: Article[];
    category: Tag;
    activity: string;
    evaluation: string;
    evaluationStandard: EvaluationStandard;
    evaluationScale: number;
    volume: number;
    volumeDetails: string;
    price: number;
    priceDetails: string;
    durability: number;
    durabilityScale: number;
    durabilityDetails: string;
    additionality: number;
    additionalityDetails: string;
    technicalBarriers: Mark;
    financialBarriers: Mark;
    socialBarriers: Mark;
    politicalBarriers: Mark;
    economicalBarriers: Mark;
    projectBarriers: Mark;
    barriersSummary: string;
    positiveEffectScale: number;
    positiveEffects: [ProjectEffect];
    coBenefitsSummary: string;
    projectAvailabilities: [any];
    objectType: string;
    website: string;
    organisationIntroduction: string;
    carbonCategory: string;
    familyCategory: string;
    solutionCategory: string;
    visible: boolean;
    surveyId: number;
    country?: {
        name: string;
    };

    constructor(data: any) {
        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name;
        this.shortDescription = data.shortDescription;
        this.longDescription = data.longDescription;
        this.coBenefitsSummary = data.coBenefitsSummary;
        this.descriptionHeadline = data.descriptionHeadline;
        this.startAt = dayjs(data.startAt).format('DD MMMM YYYY');
        this.endAt = dayjs(data.endAt).format('DD MMMM YYYY');
        this.addresses = data.addresses;
        this.quote = data.quote;
        this.company = data.company;
        this.heroImages = data.heroImages;
        this.logo = data.logo;
        this.tags = data.tags;
        this.commentThread = data.commentThread;
        this.associatedProjects = data.associatedProjects;
        this.academyArticles = data.academyArticles;
        this.category = data.category;
        this.evaluationStandard = data.evaluationStandard;
        this.evaluationScale = data.evaluationScale;
        this.volume = data.volume;
        this.volumeDetails = data.volumeDetails;
        this.price = data.price;
        this.priceDetails = data.priceDetails;
        this.durability = data.durability;
        this.durabilityScale = data.durabilityScale;
        this.durabilityDetails = data.durabilityDetails;
        this.additionality = data.additionality;
        this.additionalityDetails = data.additionalityDetails;

        this.technicalBarriers = data.technicalBarriers;
        this.financialBarriers = data.financialBarriers;
        this.socialBarriers = data.socialBarriers;
        this.politicalBarriers = data.politicalBarriers;
        this.economicalBarriers = data.economicalBarriers;
        this.projectBarriers = data.projectBarriers;
        this.barriersSummary = data.barriersSummary;

        this.positiveEffectScale = data.positiveEffectScale;
        this.positiveEffects = data.positiveEffects;
        this.projectAvailabilities = data.projectAvailabilities;
        this.objectType = ObjectTypes.PROJECT;
        this.visible = data.visible;
        this.website = data.website;
        this.organisationIntroduction = data.organisationIntroduction;

        this.carbonCategory = data.carbonCategory;
        this.familyCategory = data.familyCategory;
        this.solutionCategory = data.solutionCategory;

        this.surveyId = data.survey?.id;
        this.country = data.country;
    }
}

export interface ProjectEffect {
    id: number;
    value: string;
    isPositive: boolean;
    details: string;
}
