import {
    CalendarBlank,
    GlobeHemisphereEast,
    Leaf,
    MapPin,
    Medal,
    Money,
    Package,
    SketchLogo,
    ThermometerSimple,
    Wall
} from '@phosphor-icons/react';
import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { dateFormat } from '../../../app/common/helpers/dates';
import SDG1 from '../../../assets/images/SDG1.svg';
import SDG2 from '../../../assets/images/SDG2.svg';
import SDG3 from '../../../assets/images/SDG3.svg';
import SDG4 from '../../../assets/images/SDG4.svg';
import SDG5 from '../../../assets/images/SDG5.svg';
import SDG6 from '../../../assets/images/SDG6.svg';
import SDG7 from '../../../assets/images/SDG7.svg';
import SDG8 from '../../../assets/images/SDG8.svg';
import SDG9 from '../../../assets/images/SDG9.svg';
import SDG10 from '../../../assets/images/SDG10.svg';
import SDG11 from '../../../assets/images/SDG11.svg';
import SDG12 from '../../../assets/images/SDG12.svg';
import SDG13 from '../../../assets/images/SDG13.svg';
import SDG14 from '../../../assets/images/SDG14.svg';
import SDG15 from '../../../assets/images/SDG15.svg';
import SDG16 from '../../../assets/images/SDG16.svg';
import SDG17 from '../../../assets/images/SDG17.svg';
import { grey5 } from '../../../lib/components/bedrock/SwColors';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import {
    EVALUATION_DETAILS_HEADLINE,
    PROJECT_ADDITIONALITY_BODY,
    PROJECT_ADDITIONALITY_HEADLINE,
    PROJECT_BARRIER_BODY,
    PROJECT_CAPACITY_BODY,
    PROJECT_CAPACITY_HEADLINE,
    PROJECT_COBENEFITS_BODY,
    PROJECT_CREDITING_BODY,
    PROJECT_CREDITING_HEADLINE,
    PROJECT_DURABILITY_BODY,
    PROJECT_EVALUATION_BODY,
    PROJECT_EVALUATION_HEADLINE,
    PROJECT_IMPACT_BODY,
    PROJECT_IMPACT_HEADLINE,
    PROJECT_LOCATION_BODY,
    PROJECT_SOLUTION_BODY
} from '../../../lib/enums/AssessmentCriteria';
import { schema } from '../../../lib/helpers/crossfilter';
import { kiloFormat } from '../../../lib/helpers/number';
import { isDefined, isNotDefined } from '../../../lib/helpers/object';
import { isNotEmpty } from '../../../lib/helpers/string';
import Project, { ProjectEffect } from '../../../lib/types/project';
import SwLine from '../SwLine';
import SwVerticalSpacer from '../SwVerticalSpacer';
import SectionAssessmentBarriersTooltip, { isScoreUnavailable } from './SectionAssessmentBarriersTooltip';
import SwAssessmentDetails from './assessment/SwAssessmentDetails';
import SwAssessmentTooltip from './assessment/SwAssessmentTooltips';

const StyledGrid = styled(Grid)`
    position: relative;
`;

const StyledImage = styled('img')<{ mr?: boolean }>`
    height: 121px;
    width: 121px;
`;

export const assessmentScaleName = (scale: number) => {
    if (isNotDefined(scale)) {
        return 'N/A';
    }

    if (scale === 1) {
        return 'Low';
    }

    if (scale === 2) {
        return 'Medium';
    }

    return 'High';
};

const SectionAssessment = (props: SectionAssessmentProps) => {
    const project = new Project(props.project);

    const getSdgImg = (projectEffect: ProjectEffect) => {
        const id = parseInt(projectEffect.value.split(' ')[1]);
        switch (parseInt(id.toString())) {
            case 1:
                return SDG1;
            case 2:
                return SDG2;
            case 3:
                return SDG3;
            case 4:
                return SDG4;
            case 5:
                return SDG5;
            case 6:
                return SDG6;
            case 7:
                return SDG7;
            case 8:
                return SDG8;
            case 9:
                return SDG9;
            case 10:
                return SDG10;
            case 11:
                return SDG11;
            case 12:
                return SDG12;
            case 13:
                return SDG13;
            case 14:
                return SDG14;
            case 15:
                return SDG15;
            case 16:
                return SDG16;
            case 17:
                return SDG17;
        }
    };

    const getSdgDetails = () => {
        const sdgs = project.coBenefitsSummary?.split('\n') ?? [];
        const sdgResult = [];

        sdgs.forEach((item) => {
            const splittedItem = item.split(/(\d*):/);

            const id = parseInt(splittedItem[1]);
            const description = splittedItem[2]?.trim();

            const title = schema.positiveEffects.hintForDataGeneration[id - 1]?.replace('-', ': ');

            if (isDefined(description) && isNotEmpty(description)) {
                sdgResult.push({
                    title,
                    description
                });
            }
        });

        return sdgResult;
    };

    const isDurabilityApplicable: () => boolean = () =>
        isDefined(project.category?.group) && project.category?.group !== 'Reduction';

    return (
        <Grid container={true}>
            {props.showDescription && (
                <>
                    <Grid item={true} xs={12}>
                        <SwVerticalSpacer spacing={4} />
                    </Grid>
                    <Grid item={true} lg={props.compactAssessment ? 12 : 3} xs={12}>
                        <StyledGrid container={true}>
                            <Grid item={true} xs={12}>
                                <Typography variant={props.compactAssessment ? 'h5' : 'h4'}>ASSESSMENT</Typography>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwVerticalSpacer spacing={2} />
                            </Grid>
                            <Grid item={true} lg={8} xs={6}>
                                <Typography variant={'body2'}>Everything about this project at a glance.</Typography>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwVerticalSpacer spacing={4} />
                            </Grid>
                        </StyledGrid>
                    </Grid>
                </>
            )}
            <Grid item={true} lg={props.compactAssessment ? 12 : 9} xs={12}>
                <Grid container={true}>
                    <Grid container={true}>
                        <Grid alignItems={'center'} container={true}>
                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={
                                        <SwAssessmentTooltip
                                            body={PROJECT_IMPACT_BODY}
                                            headline={PROJECT_IMPACT_HEADLINE}
                                        />
                                    }
                                    icon={<ThermometerSimple />}
                                    name={'Climate impact'}
                                    showInfo={true}
                                    tooltipAssessmentContent={project.familyCategory}
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={<SwAssessmentTooltip body={PROJECT_SOLUTION_BODY} />}
                                    icon={<Leaf />}
                                    name={'Solution'}
                                    showInfo={true}
                                    tooltipAssessmentContent={project.solutionCategory}
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={<SwAssessmentTooltip body={PROJECT_LOCATION_BODY} />}
                                    icon={<MapPin />}
                                    name={'Location'}
                                    showInfo={true}
                                    tooltipAssessmentContent={props.project.country?.name}
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={
                                        <SwAssessmentTooltip
                                            body={PROJECT_EVALUATION_BODY}
                                            headline={PROJECT_EVALUATION_HEADLINE}
                                        />
                                    }
                                    icon={<Medal />}
                                    name={'Evaluation'}
                                    showInfo={true}
                                    tooltipAssessmentContent={
                                        isDefined(project.carbonCategory) &&
                                        isDefined(project.evaluationStandard?.label)
                                            ? EVALUATION_DETAILS_HEADLINE(
                                                  project.carbonCategory,
                                                  project.evaluationStandard?.label
                                              )
                                            : null
                                    }
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={
                                        <SwAssessmentTooltip
                                            body={PROJECT_CAPACITY_BODY}
                                            headline={PROJECT_CAPACITY_HEADLINE}
                                        />
                                    }
                                    icon={<Package />}
                                    name={'Capacity'}
                                    showInfo={true}
                                    tooltipAssessmentContent={
                                        isDefined(project.volume) ? kiloFormat(project.volume) + 't' : 'N/A'
                                    }
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={
                                        <SwAssessmentTooltip
                                            body={PROJECT_CREDITING_BODY}
                                            headline={PROJECT_CREDITING_HEADLINE}
                                        />
                                    }
                                    icon={<CalendarBlank />}
                                    name={'Crediting Period'}
                                    showInfo={true}
                                    tooltipAssessmentContent={`${
                                        isDefined(project.startAt) ? dateFormat(project.startAt, 'YYYY') : 'N/A'
                                    } - ${isDefined(project.endAt) ? dateFormat(project.endAt, 'YYYY') : 'N/A'}`}
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={<SwAssessmentTooltip body={PROJECT_COBENEFITS_BODY} />}
                                    icon={<GlobeHemisphereEast />}
                                    name={'Co-benefits'}
                                    showInfo={true}
                                    tooltipAssessmentContent={props.project.positiveEffects.length + ' SDGs'}
                                >
                                    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <SwTypography variant={props.compactAssessment ? 'h5' : 'h4'}>
                                                {props.project.positiveEffects.length} SDGs
                                            </SwTypography>
                                            <SwSpacer direction={'vertical'} spacing={1.5} />
                                            <Box columnGap={8} display={'flex'} flexWrap={'wrap'} rowGap={8}>
                                                {props.project.positiveEffects.map((groupEffect, index) => (
                                                    <Grid item={true} key={index}>
                                                        <Box display={'flex'}>
                                                            <StyledImage mr={true} src={getSdgImg(groupEffect)} />
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>

                                        {props.project.positiveEffects.length > 0 ? (
                                            <Box display={'flex'} flexDirection={'column'}>
                                                {getSdgDetails().map((sdg, index) => (
                                                    <Grid item={true} key={index}>
                                                        <SwLine color={grey5} />
                                                        <SwTypography bold={true}>SDG {sdg.title}</SwTypography>
                                                        <SwSpacer direction={'vertical'} spacing={1} />
                                                        <SwTypography>{sdg.description}</SwTypography>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </SwAssessmentDetails>
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={<SwAssessmentTooltip body={PROJECT_DURABILITY_BODY} />}
                                    icon={<SketchLogo />}
                                    name={'Durability'}
                                    showInfo={true}
                                    tooltipAssessmentContent={
                                        isDurabilityApplicable()
                                            ? assessmentScaleName(props.project.durabilityScale)
                                            : 'N/A'
                                    }
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={
                                        <SwAssessmentTooltip
                                            body={PROJECT_ADDITIONALITY_BODY}
                                            headline={PROJECT_ADDITIONALITY_HEADLINE}
                                        />
                                    }
                                    icon={<Money />}
                                    name={'Financial additionality'}
                                    showInfo={true}
                                    tooltipAssessmentContent={assessmentScaleName(project.additionality)}
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwSpacer direction={'vertical'} spacing={1} />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <SwAssessmentDetails
                                    compactDisplay={props.compactAssessment}
                                    half={!props.compactAssessment}
                                    howTo={<SwAssessmentTooltip body={PROJECT_BARRIER_BODY} />}
                                    icon={<Wall />}
                                    name={'Barriers'}
                                    showInfo={true}
                                    tooltipAssessmentContent={
                                        isScoreUnavailable(project)
                                            ? 'N/A'
                                            : assessmentScaleName(project.projectBarriers)
                                    }
                                >
                                    <SectionAssessmentBarriersTooltip project={project} />
                                </SwAssessmentDetails>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <SwVerticalSpacer spacing={6} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface SectionAssessmentProps {
    project: Project;
    compactAssessment: boolean;
    showDescription: boolean;
}
export default SectionAssessment;
