import React from 'react';
import { Grid } from '@mui/material';
import { cropBrownDark, fireRedStrong, grassGreenStrong, grey6 } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { Mark } from '../../../lib/types/project';
import SwCircle from '../SwCircle';

interface SwCriteriaRowMarkProps {
    title: string;
    mark: Mark;
}

const SwCriteriaMark = (props: SwCriteriaRowMarkProps) => {
    const markToColor = (mark: Mark) => {
        switch (mark) {
            case 1:
                return grassGreenStrong;
            case 2:
                return cropBrownDark;
            case 3:
                return fireRedStrong;
            default:
                return grey6;
        }
    };
    const markToText = (mark: Mark) => {
        switch (mark) {
            case 1:
                return 'Low';
            case 2:
                return 'Medium';
            case 3:
                return 'High';
            default:
                return 'N/A';
        }
    };
    return (
        <Grid alignItems={'center'} container={true}>
            <Grid item={true} xs={true}>
                <SwTypography variant={'h5'}>{props.title}</SwTypography>
            </Grid>
            <Grid item={true} xs={true}>
                <Grid alignItems={'center'} container={true} spacing={1}>
                    <Grid item={true}>
                        <SwCircle color={markToColor(props.mark)} diameter={8} />
                    </Grid>
                    <Grid item={true}>
                        <SwTypography variant={'caption'}>{markToText(props.mark)}</SwTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SwCriteriaMark;
