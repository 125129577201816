import React, { Component, createRef } from 'react';

export interface WithPickerProps {
    fileSrc: string;
    fileSize: number;
    initialFileName: string;
    selectFile: (event) => void;
    onFileDropped: (file: File) => void;
    resetFile: () => void;
    file: File;
    name?: string;
    id?: string;
}

interface WithPickerState {
    file: File;
    fileSrc: string;
    fileSize: number;
    fileTypes: string[];
    initialFileName: string;
}

const WithPicker = (fileTypes: string[]) => (WrappedComponent) => {
    return class extends Component<any, WithPickerState> {
        private inputRef: React.RefObject<HTMLInputElement>;

        constructor(props: WithPickerProps) {
            super(props);
            this.selectFile = this.selectFile.bind(this);
            this.uploadFile = this.uploadFile.bind(this);
            this.onFileDropped = this.onFileDropped.bind(this);
            this.onFileSelected = this.onFileSelected.bind(this);
            this.resetFile = this.resetFile.bind(this);
            this.state = {
                file: undefined,
                fileSize: 0,
                fileSrc: '',
                initialFileName: '',
                fileTypes: fileTypes
            };
            this.inputRef = createRef();
        }

        resetFile() {
            this.setState({
                file: undefined,
                fileSize: 0,
                fileSrc: '',
                initialFileName: ''
            });
        }

        selectFile() {
            this.inputRef.current.click();
        }

        uploadFile(file: File) {
            const acceptedFileTypes = this.state.fileTypes;

            if (acceptedFileTypes.indexOf(file.type) === -1) {
                alert('Not a supported file type');
            } else {
                this.setState({
                    initialFileName: file.name,
                    fileSize: file.size,
                    fileSrc: file.name,
                    file: file
                });
            }
        }

        onFileDropped(file: File) {
            this.uploadFile(file);
        }

        onFileSelected(event) {
            const file = event.target.files[0];
            if (file === undefined) {
                return;
            }
            this.uploadFile(file);
        }

        render() {
            return (
                <>
                    <input
                        id={this.props.id ?? 'file'}
                        name={this.props.name}
                        ref={this.inputRef}
                        style={{ display: 'none' }}
                        type={'file'}
                        onChange={this.onFileSelected}
                    />
                    <WrappedComponent
                        file={this.state.file}
                        fileSize={this.state.fileSize}
                        fileSrc={this.state.fileSrc}
                        initialFileName={this.state.initialFileName}
                        resetFile={this.resetFile}
                        selectFile={this.selectFile}
                        onFileDropped={this.onFileDropped}
                        {...this.props}
                    />
                </>
            );
        }
    };
};

export const WithImagePicker = WithPicker(['image/jpeg', 'image/png']);
export const WithPrivateImagePicker = WithPicker(['image/jpeg', 'image/png']);
export const WithDocumentPicker = WithPicker([
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
    'image/jpeg',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]);
export const WithDocumentDataPicker = WithPicker(['text/csv', 'application/csv', 'application/vnd.ms-excel']);
