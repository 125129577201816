import React from 'react';
import { Grid, Box } from '@mui/material';
import { white, grey4 } from '../../lib/components/bedrock/SwColors';
import SwContainer from './SwContainer';
import SwLine from './SwLine';
import SwVerticalSpacer from './SwVerticalSpacer';

const SwEndOfSection = (props: SwEndOfSectionProps) => {
    return (
        <Box bgcolor={props.background || white}>
            {!props.withLineSeparator ? (
                <SwContainer withMaxWidth={true}>
                    <SwVerticalSpacer spacing={props.spacing ? props.spacing : 8} />
                </SwContainer>
            ) : (
                <SwContainer withMaxWidth={true}>
                    <Grid container={true}>
                        <Grid item={true} xs={12}>
                            <SwLine
                                color={props.lineColor ? props.lineColor : grey4}
                                spacing={props.spacing ? props.spacing : 4}
                            />
                        </Grid>
                    </Grid>
                </SwContainer>
            )}
        </Box>
    );
};

interface SwEndOfSectionProps {
    spacing?: number;
    background?: string;
    withLineSeparator?: boolean;
    lineColor?: string;
}

export default SwEndOfSection;
