import { glacierBlueStrong, skyBlueStrong } from '../../lib/components/bedrock/SwColors';

enum TagGroupEnum {
    Academy = 'Academy',
    Avoidance = 'Avoidance',
    Company = 'Company',
    Other = 'Other',
    Reduction = 'Reduction',
    Removal = 'Removal',
    System = 'System'
}

export const TagTypes = {
    FOREST: { label: 'Forests', color: skyBlueStrong },
    WATER: { label: 'Water & Blue carbon', color: skyBlueStrong },
    SOILS: { label: 'Soils & Agriculture', color: skyBlueStrong },
    BIOMASS: { label: 'Bio Energy + CCS', color: skyBlueStrong },
    DAC: { label: 'Direct Air Capture + CCS', color: skyBlueStrong },
    GEOENGINEERING: { label: 'Geoengineering', color: skyBlueStrong },
    INDUSTRY: { label: 'Industry', color: glacierBlueStrong },
    BUILDINGS: { label: 'Buildings', color: glacierBlueStrong },
    ENERGY: { label: 'Energy', color: glacierBlueStrong },
    TRANSPORT: { label: 'Transport', color: glacierBlueStrong },
    LAND_USE: { label: 'Land Use', color: glacierBlueStrong },
    CONSERVATION: { label: 'Conservation', color: glacierBlueStrong },
    COMMUNITY: { label: 'Community', color: glacierBlueStrong }
};

export const TagGroups = {
    [TagGroupEnum.Removal]: { color: skyBlueStrong },
    [TagGroupEnum.Reduction]: { color: glacierBlueStrong }
};

export const TagDisplayInfos = {
    [TagGroupEnum.Removal]: { ...TagGroups.Removal, text: 'Removal' },
    [TagGroupEnum.Reduction]: { ...TagGroups.Reduction, text: 'Reduction' }
};

export const getTagTypesBadgeFormat = () => {
    const obj = {};
    Object.keys(TagTypes).forEach((key) => {
        obj[key] = {
            text: TagTypes[key].label,
            color: TagTypes[key].color
        };
    });

    return obj;
};

export const getTagBadgeFormat = () => {
    const obj = {};
    Object.keys(TagTypes).forEach((key) => {
        obj[TagTypes[key].label] = {
            text: TagTypes[key].label,
            color: TagTypes[key].color
        };
    });

    return obj;
};

export const getTagColorMapping = () => {
    const obj = {};
    Object.keys(TagTypes).forEach((key) => {
        obj[TagTypes[key].label] = TagTypes[key].color;
    });

    return obj;
};
