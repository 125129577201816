import { Envelope } from '@phosphor-icons/react';
import React from 'react';
import { Box, styled } from '@mui/material';
import { navigate } from 'gatsby';
import ContributeHero from '../../../assets/images/contribute-hero.svg';
import { grey3, grey5 } from '../../../lib/components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '../../../lib/components/bedrock/button/SwButton';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';

const StyledBox = styled(Box)`
    border: 1px solid ${grey5};
    border-radius: 8px;
    overflow: hidden;
    height: 165px;
`;

const StyledImage = styled('img')`
    height: 185px;
    padding-left: ${({ theme }) => theme.spacing(4)};
    margin-top: -20px;
`;

const StyledPortoflio = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2)};
`;

const SectionCreatePortfolio = () => {
    return (
        <StyledBox alignItems={'center'} display={'flex'} gap={'8px'}>
            <StyledImage alt={'section hero'} src={ContributeHero} />

            <StyledPortoflio alignItems={'flex-start'} display={'flex'} flexDirection={'column'}>
                <SwTypography variant={'h4'}>Create your portfolio to get started</SwTypography>

                <SwTypography color={grey3} variant={'body2'}>
                    Our carbon team will help you tailor your portfolio based on your preferences.
                </SwTypography>

                <SwSpacer direction={'vertical'} spacing={3} />

                <SwButton
                    kind={SwButtonKind.Secondary}
                    startIcon={<Envelope />}
                    onClick={() => navigate('/get-in-touch')}
                >
                    Get in touch
                </SwButton>
            </StyledPortoflio>
        </StyledBox>
    );
};

export default SectionCreatePortfolio;
