import React from 'react';
import { graphql } from 'gatsby';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwProjectPage from '../components/v1/projects/SwProjectPage';
import SwTopNav from '../components/v1/website/SwTopNav';
import { getImageUrl } from '../lib/helpers/image';
import Project from '../lib/types/project';

const ProjectTemplate = ({ pageContext: { project: data }, data: pageData }) => {
    const project = new Project({ ...data, company: { ...data.company, users: data.company.users.nodes } });

    return (
        <>
            <FlashBanner banner={pageData.flashBanners.edges[0].node} />
            <SwTopNav activePage={WEBSITE_MENU_ITEM.catalogue.key} latestUpdates={pageData.latestArticles.nodes} />
            <SwLayout>
                <SwProjectPage pageData={pageData} project={project} />
            </SwLayout>
        </>
    );
};

export const Head = ({ pageContext: { project: data } }) => {
    const project = new Project({ ...data, company: { ...data.company, users: data.company.users.nodes } });

    return (
        <Seo
            description={project.shortDescription}
            image={getImageUrl(project.heroImages[0], 324)}
            pathname={`/catalogue2/${project.slug}`}
            title={project.name}
        />
    );
};

export const query = graphql`
    {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: en } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
    }
`;

export default ProjectTemplate;
