import { isDefined } from '../../lib/helpers/object';

enum BillingSubscriptionCurrency {
    Eur = 'EUR',
    Usd = 'USD'
}

const ranges = [
    { divider: 1e24, suffix: 'Y' },
    { divider: 1e21, suffix: 'Z' },
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' }
];

export function kiloFormat(number: number, precision = 2): string {
    if (isTiny(number, precision)) {
        return formatTiny(number, precision);
    } else {
        const absNumber = Math.abs(number);
        let result = numberToString(round(absNumber, precision), precision);

        for (const range of ranges) {
            if (absNumber >= range.divider) {
                result = numberToString(round(absNumber / range.divider, precision), precision) + range.suffix;
                break;
            }
        }

        if (number < 0) {
            return `-${result}`;
        } else {
            return result;
        }
    }
}

export function numberFormat(number: number, precision = 2): string {
    if (number === null || number === undefined) {
        return null;
    }

    if (isTiny(number, precision)) {
        return formatTiny(number, precision);
    } else {
        return numberToString(round(number, precision), precision);
    }
}

const isTiny = (number: number, precision: number): boolean => {
    const rounded = round(number, precision);
    if (isNaN(number)) {
        return false;
    } else {
        return rounded === 0 && number !== 0;
    }
};

const formatTiny = (number: number, precision: number): string => {
    const tinyNumber = numberToString(1 / Math.pow(10, precision || 0), precision);
    if (number > 0) {
        return `< ${tinyNumber}`;
    } else {
        return `> -${tinyNumber}`;
    }
};

export function round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);

    return Math.round(value * multiplier) / multiplier;
}

function numberToString(number: number, precision: number): string {
    const decimal = number - Math.floor(number);
    const precisionToApply = decimal !== 0 ? precision : 0;

    return formatNumber(number, {
        minimumFractionDigits: precisionToApply,
        maximumFractionDigits: precisionToApply
    });
}

export function formatNumber(number: number, options: Intl.NumberFormatOptions = {}) {
    if (isDefined(number)) {
        return number.toLocaleString('fr-FR', { maximumFractionDigits: 20, ...options }).replace(',', '.');
    } else {
        return null;
    }
}

export function parseFormattedFloat(value: string | null) {
    return parseFloat(value?.replace(/\s+/g, ''));
}

export function safeParseInt(value: string | null): number | null {
    const parsed = parseInt(value);
    if (!isNaN(parsed)) {
        return parsed;
    } else {
        return null;
    }
}

export function safeParseFloat(value: string | null): number | null {
    const parsed = parseFloat(value);
    if (!isNaN(parsed)) {
        return parsed;
    } else {
        return null;
    }
}

export function formatCurrencyNumber(value: number, currency: BillingSubscriptionCurrency): string {
    switch (currency) {
        case BillingSubscriptionCurrency.Eur:
            return `${numberFormat(value)}€`;
        case BillingSubscriptionCurrency.Usd:
            return `$${numberFormat(value)}`;
    }
}
