export const schema = {
    projectName: {
        displayName: 'Name',
        hintForDataGeneration: 'random'
    },
    projectId: {
        displayName: 'id',
        hintForDataGeneration: 'random'
    },
    projectDescription: {
        displayName: 'Description',
        hintForDataGeneration: [
            'Technology from Switzerland that removes carbon dioxide from the air and turns it into stone or sparkling water.'
        ]
    },
    projectLocation: {
        displayName: 'Location',
        hintForDataGeneration: ['Zurich, CH', 'Montreal, CA', 'Hossegor, FR', 'Montpellier, FR']
    },
    solution: {
        displayName: 'Solutions',
        hintForDataGeneration: [
            'Water',
            'Waste',
            'Energy',
            'Community',
            'Agriculture',
            'DAC',
            'BECC',
            'Forest',
            'Soil carbon',
            'Biochar',
            'Enhanced weathering'
        ]
    },
    solutionCategory: {
        displayName: 'Solutions',
        hintForDataGeneration: ['Removal', 'Reduction']
    },
    //https://en.wikipedia.org/wiki/ISO_3166-1_numeric
    locationCaptureCountryCode: {
        displayName: 'Project country',
        scaleLabel: {
            '250': 'France',
            '840': 'USA',
            '826': 'UK',
            '764': 'China',
            '156': 'Thailand',
            '352': 'Iceland'
        },
        hintForDataGeneration: ['250', '840', '826', '764', '156', '352']
    },
    locationCaptureCountryDisplayName: {
        displayName: 'Project country',
        hintForDataGeneration: [
            'France',
            'USA',
            'UK',
            'Thailand',
            'China',
            'Iceland',
            'France2',
            'USA2',
            'UK2',
            'Thailand2',
            'China2',
            'Iceland2',
            'France3    ',
            'USA3',
            'UK3',
            'Thailand3',
            'China3',
            'Liechtenstein'
        ]
    },
    //https://en.wikipedia.org/wiki/ISO_3166-1_numeric
    locationStorageCountryCode: {
        displayName: 'Project country',
        scaleLabel: {
            '250': 'France',
            '840': 'USA',
            '826': 'UK',
            '764': 'China',
            '156': 'Thailand',
            '352': 'Iceland'
        },
        hintForDataGeneration: ['250', '840 ', '826', '764', '156', '352']
    },
    locationStorageCountryDisplayName: {
        displayName: 'Project country',
        hintForDataGeneration: [
            'France',
            'USA',
            'UK',
            'Thailand',
            'China',
            'Iceland',
            'France2',
            'USA2',
            'UK2',
            'Thailand2',
            'China2',
            'Iceland2',
            'France3    ',
            'USA3',
            'UK3',
            'Thailand3',
            'China3',
            'Liechtenstein'
        ]
    },
    locationCategory: {
        displayName: 'Location',
        hintForDataGeneration: ['Local', 'Global']
    },
    evaluationScale: {
        displayName: 'Evaluation',

        hintForDataGeneration: ['1', '2', '3'],
        scaleLabel: { '1': 'Low', '2': 'Medium', '3': 'High' }
    },
    evaluationStandard: {
        displayName: 'Evaluation Standard',
        hintForDataGeneration: [
            'ONU',
            'Gold Standards',
            'REDD+',
            'Climat Action Reserve',
            'Plan Vivo',
            'Social Carbon Standard',
            'W+ Standard',
            'Water Benefits Standard',
            'Fairtrade Climate Standard',
            'Sweep',
            'verra'
        ]
    },
    evaluationCategory: {
        displayName: 'Evaluation',
        hintForDataGeneration: ['Innovation', 'Certification']
    },
    volume: {
        displayName: 'Volume',
        displayNameInASeries: 'Now',
        isMeasure: true,
        hintForDataGeneration: { minimum: 10, maximum: 1000 }
    },
    cost: {
        displayName: 'Cost',
        displayNameInASeries: 'Now',
        isMeasure: true,
        hintForDataGeneration: { minimum: 10, maximum: 3000 }
    },
    price: {
        displayName: 'Price',
        displayNameInASeries: 'Now',
        isMeasure: true,
        hintForDataGeneration: { minimum: 3, maximum: 1000 }
    },
    durabilityScale: {
        displayName: 'Durability',

        hintForDataGeneration: ['1', '2', '3'],
        derivedFrom: 'durabilityYears',
        scaleLabel: { '1': '10-50', '2': '50-100', '3': '100-1000+' }
    },
    durabilityYears: {
        displayName: 'Durability in years',
        hintForDataGeneration: ['20', '70', '90'],
        scaleLabel: { '1': 'Low', '2': 'Medium', '3': 'High' }
    },
    additionality: {
        displayName: 'Additionality',

        hintForDataGeneration: ['1', '2', '3'],
        scaleLabel: { '1': 'Low', '2': 'Medium', '3': 'High' }
    },
    projectBarriers: {
        displayName: 'Project Barriers',

        hintForDataGeneration: ['1', '2', '3'],
        scaleLabel: { '1': 'Low', '2': 'Medium', '3': 'High' }
    },
    positiveEffectScale: {
        displayName: 'Positive Effects ',

        hintForDataGeneration: ['1', '2', '3'],
        scaleLabel: { '1': 'Low', '2': 'Medium', '3': 'High' }
    },
    positiveEffects: {
        displayName: 'Positive Effects ',
        hintForDataGeneration: [
            '1-No Poverty',
            '2-Zero Hunger',
            '3-Good Health and Well-being',
            '4-Quality Education',
            '5-Gender Equality',
            '6-Clean Water and Sanitation',
            '7-Affordable and Clean Energy',
            '8-Decent Work and Economic Growth',
            '9-Industry, Innovation, and Infrastructure',
            '10-Reducing Inequality',
            '11-Sustainable Cities and Communities',
            '12-Responsible Consumption and Production',
            '13-Climate Action',
            '14-Life Below Water',
            '15-Life On Land',
            '16-Peace, Justice, and Strong Institutions',
            '17-Partnerships for the Goals'
        ]
    },
    projectImage: {
        displayName: 'Project image',
        hintForDataGeneration: [
            '2f0cc8fa-e38c-4042-a9c5-8eb971481203.jpeg',
            '386e3aa3-a44a-4f0d-8ce5-0be93826b338.jpg',
            '3bf33993.jpeg',
            '3f127bb7-0a7e-4cc5-972f-996d8e8c3e6e.jpeg',
            '44197a80-1520-4795-babe-3f4aa1e6f1f3.png',
            '44cf8288-3336-4102-ae5e-852257c5ab04.png',
            '5faffe77-96d6-4cb3-ab84-271d0c768f83.png',
            '7099910c-b8e1-4388-8b47-056d90781db5.jpeg',
            '7127dace-b572-4941-9b23-8d22900a8473.jpeg',
            '7696ded8-4709-4787-96d6-f6bf7a42a811.jpg',
            'a0751f0b-c3b0-4ccb-9c53-3ba5e85e362c.jpg',
            'a8f5c78b-fd28-4b62-ac9b-9ffc4c11827b.jpg',
            'acec5798-a458-4f04-ae6e-8dd10d5db21e.png',
            'photo-1420593248178-d88870618ca0.jpeg',
            'photo-1458130713137-8b7237fb3648.jpeg',
            'photo-1460469235493-43425878ad2d.jpeg',
            'photo-1461220830992-5a8d6a54af75.jpeg',
            'photo-1461938337379-4b537cd2db74.jpeg',
            'photo-1466629437334-b4f6603563c5.jpeg',
            'photo-1472099645785-5658abf4ff4e.jpeg',
            'photo-1494861895304-fb272971c078.jpeg',
            'photo-1497250681960-ef046c08a56e.jpeg',
            'photo-1498612753354-772a30629934.jpeg',
            'photo-1499848144902-af767f6d0c7f.jpeg',
            'photo-1504917595217-d4dc5ebe6122.jpeg',
            'photo-1510797948806-f73deda56aa3.jpeg',
            'photo-1516216628859-9bccecab13ca.jpeg',
            'photo-1517999144091-3d9dca6d1e43.jpeg',
            'photo-1538300342682-cf57afb97285.jpeg',
            'photo-1543716627-839b54c40519.jpeg',
            'photo-1546082373-472b0bedd449.jpeg',
            'photo-1553531087-b25a0b9a68ab.jpeg',
            'photo-1572375180666-c23ef8bef639.jpeg',
            'photo-1572922890198-3d1e387fef2f.jpeg',
            'photo-1580120656760-c652daad203c.jpeg',
            'photo-1584921467312-99ff98cc2ebd.jpeg',
            'photo-1589201529153-5297335c1684.jpeg'
        ]
    },
    // purchaseDate: {
    //     displayName: 'Purchase date',
    //     isDate: true,
    //     hintForDataGeneration: { minimum: '01/01/2019', maximum: '01/01/2021' }
    // },
    // listingDate: {
    //     displayName: 'Listing date',
    //     isDate: true,
    //     hintForDataGeneration: { minimum: '01/01/2019', maximum: '12/31/2021' }
    // },
    volumeBought: {
        displayName: 'Volume',
        isMeasure: true,
        hintForDataGeneration: { minimum: 10, maximum: 200 }
    }
};
