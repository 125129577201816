import React from 'react';
import { Avatar, Grid, styled } from '@mui/material';
import { grey3 } from '../../../lib/components/bedrock/SwColors';
import SwFlexBox from '../../../lib/components/bedrock/layout/SwFlexBox';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import SwLink from '../../../lib/components/bedrock/typography/SwLink';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { InputTypes } from '../../../lib/enums/InputTypes';
import { isDefined } from '../../../lib/helpers/object';
import Project from '../../../lib/types/project';
import SwSectionCard from '../SwSectionCard';
import SwEditableTypography from '../typography/SwEditableTypography';

const StyledGrid = styled(Grid)`
    @media (max-width: 600px) {
        width: 100%;
        flex: 0 0 auto;
    }
`;

interface SectionCompanyDescriptionProps {
    project: Project;
}

const SectionCompanyDescription: React.FunctionComponent<SectionCompanyDescriptionProps> = (props) => {
    const { project } = props;

    return (
        <>
            <Grid item={true} sm={3} xs={12}>
                <SwTypography variant={'h4'}>ABOUT</SwTypography>
                <SwSpacer direction={'vertical'} spacing={2} />
                <SwTypography>
                    Learn more about the <br />
                    project
                </SwTypography>
            </Grid>
            <Grid item={true} sm={9} xs={12}>
                <StyledGrid item={true}>
                    {isDefined(project?.longDescription) ? (
                        <SwEditableTypography data={project} field={'longDescription'} inputType={InputTypes.MARKDOWN}>
                            {project.longDescription}
                        </SwEditableTypography>
                    ) : (
                        <SwTypography>N/A</SwTypography>
                    )}
                </StyledGrid>

                <SwSpacer direction={'vertical'} spacing={4} />

                <SwSectionCard
                    defaultOpen={false}
                    description={isDefined(project.organisationIntroduction) ? project.organisationIntroduction : 'N/A'}
                    fixedArrow={true}
                    fullWidth={true}
                    hideLine={true}
                    openable={true}
                    overview={true}
                    title={
                        <SwFlexBox alignItems={'center'}>
                            {isDefined(project.logoProject) ? (
                                <>
                                    <SwFlexBox alignItems={'center'} height={'50px'} width={'50px'}>
                                        <Avatar src={project.logoProject} />
                                    </SwFlexBox>
                                    <SwSpacer direction={'horizontal'} spacing={1} />
                                </>
                            ) : null}
                            <SwTypography variant={'h4'}>{project.company.name}</SwTypography>
                        </SwFlexBox>
                    }
                >
                    <SwSpacer direction={'vertical'} spacing={2} />
                    <SwTypography color={grey3}>
                        {project.country && project.country.name} &#8226;{' '}
                        {isDefined(project.website) ? (
                            <SwLink color={grey3} href={project.website} useNewTab={true}>
                                {project.website}
                            </SwLink>
                        ) : (
                            <SwTypography component={'span'}>N/A</SwTypography>
                        )}
                    </SwTypography>
                </SwSectionCard>
            </Grid>
        </>
    );
};

export default SectionCompanyDescription;
