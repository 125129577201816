import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { white } from '../../lib/components/bedrock/SwColors';
import { zIndexes } from '../../lib/enums/zIndexes';

// This style is unusual but necessary as the tooltip is a child of the body
// and can't be accessed via a CSS selection from within this component.
export interface SwTooltipProps extends TooltipProps {
    width?: number;
    zindex?: number;
    padding?: number;
    marginx?: number;
    marginy?: number;
}

export const SwTooltip = withStyles(() => ({
    popper: {
        zIndex: (props: SwTooltipProps) => props.zindex || zIndexes.TOOLTIP
    },
    tooltip: {
        width: (props: SwTooltipProps) => props.width || 250,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        border: 'none',
        borderRadius: 8,
        padding: ({ padding }: SwTooltipProps) => (padding !== undefined ? `${padding}px` : undefined),
        margin: ({ marginx, marginy }: SwTooltipProps) =>
            marginx !== undefined && marginy !== undefined ? `${marginy}px ${marginx}px` : undefined
    },
    arrow: {
        color: white
    }
}))(Tooltip) as React.FunctionComponent<SwTooltipProps>;
