import React from 'react';
import { Box, Grid } from '@mui/material';
import { InputTypes } from '../../../lib/enums/InputTypes';
import { isDefined } from '../../../lib/helpers/object';
import Project from '../../../lib/types/project';
import SwVerticalSpacer from '../SwVerticalSpacer';
import SwEditableTypography from '../typography/SwEditableTypography';
import SwAssessmentCard from './assessment/SwAssessmentCard';

interface SectionHeaderProps {
    project: Project;
    goToAssessment: () => void;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = (props) => {
    const { project, goToAssessment } = props;

    return (
        <Grid container={true}>
            <Grid item={true} lg={7} xs={12}>
                <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'center'}>
                    <SwEditableTypography
                        charactersMax={22}
                        component={'h1'}
                        data={project}
                        field={'name'}
                        inputType={InputTypes.SIMPLE_TEXT}
                        isMultiline={true}
                        prompt={'Project name'}
                        rowsMax={2}
                        variant={'h2'}
                    >
                        {isDefined(project.name) ? project.name : 'N/A'}
                    </SwEditableTypography>
                    <SwVerticalSpacer spacing={4} />
                    <Grid container={true} justifyContent={'center'}>
                        <Grid item={true} xs={9}>
                            <SwEditableTypography
                                charactersMax={115}
                                data={project}
                                field={'shortDescription'}
                                inputType={InputTypes.SIMPLE_TEXT}
                                isMultiline={true}
                                prompt={'Short description'}
                                rowsMax={3}
                                variant={'h4'}
                            >
                                {isDefined(project.shortDescription) ? project.shortDescription : 'N/A'}
                            </SwEditableTypography>
                        </Grid>
                        <Grid item={true} xs={3} />
                    </Grid>
                </Box>
            </Grid>
            <Grid item={true} lg={5} xs={12}>
                <SwAssessmentCard goToAssessment={goToAssessment} project={project} />
            </Grid>
        </Grid>
    );
};

export default SectionHeader;
